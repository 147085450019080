<template>
  <v-container>
  <v-tabs v-model="activeTab" @change="tabChange" :key="destinationFormsKey">
    <v-tab :key="0">Flow</v-tab>
    <v-tab :key="1">Destination</v-tab>
    <v-tab :key="2">Options</v-tab>
    <v-tab :key="3">Pipeline</v-tab>
    <v-tab :key="4">Raw data</v-tab>
    <v-tab :key="5" v-if="debug">Applyed schema</v-tab>
    <v-tab :key="6" v-if="debug">Applyed UI</v-tab>
    <v-tab-item :key="0">
      <json-forms :data="objData" :schema="schemaFlow" :uischema="uischemaFlow"
          @change="onChangeSourceForm" :renderers="renderers" ref="formdbase"
                  :validationMode="'ValidateAndShow'" :ajv="ajv"
      />
    </v-tab-item>
    <v-tab-item :key="1">
      <json-forms
          :data="objData" :schema="schemaFlow" :uischema="uischemaFlowDestination"
          @change="onChangeDestinationForm" :renderers="renderers" ref="formdest"
          :validationMode="'validateAndShow'" :ajv="ajv"
      />
    </v-tab-item>
    <v-tab-item :key="2">
      <json-forms
          :data="objData" :schema="schemaFlow" :uischema="uischemaFlowOptions"
          @change="onChangeOptionsForm" :renderers="renderers" ref="formopt"
          :validationMode="'validateAndShow'" :ajv="ajv"
      />
    </v-tab-item>
    <v-tab-item :key="3">
      <json-forms
          :data="objData" :schema="schemaFlow" :uischema="uischemaFlowPipeline"
          @change="onChangePipelineForm" :renderers="renderers" ref="formpipeline"
          :validationMode="'validateAndShow'" :ajv="ajv"
      />
    </v-tab-item>
    <v-tab-item :key="4">
      <vue-monaco-editor v-model="code" height="600px" language="json" loading="false" />
    </v-tab-item>
    <!--
    <v-tab-item :key="5">
      <vue-monaco-editor v-model="appliedSchema" height="600px" language="json"loading="false" />
    </v-tab-item>
    <v-tab-item :key="6">
      <vue-monaco-editor v-model="appliedUI" height="600px" language="json" loading="false"/>
    </v-tab-item>
    -->
  </v-tabs>
</v-container>
</template>

<script>

import { JsonForms } from "@jsonforms/vue2";
import { vuetifyRenderers } from "@jsonforms/vue2-vuetify";
import MapRender from "./renderers/MapRender";
import MonacoRender from "./renderers/MonacoRender";
import Vuetify from "vuetify";
import Vue from "vue";
import schemas from "../../services/schemas";
import ApiSelectRender from "./renderers/ApiSelectRender.vue";
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
Vue.use(Vuetify);

import {getElementByScope} from '@/services/uiFormUtils';
import PlatformRender from "@/components/shared/renderers/PlatformRender.vue";

function recursiveBeta(uischema,isBeta){
  if(uischema.hasOwnProperty("options")){
    //console.log(uischema.label+">has options", console.log(uischema.options));
    let options=uischema.options;
    if(options.hasOwnProperty("beta")&& options.beta===true){
      uischema["rule"]={
        "effect": isBeta? "SHOW":"HIDE",
        "condition": true
      };
    }
  }
  if(uischema.hasOwnProperty("elements")){
    uischema?.elements?.forEach(x=>{
        recursiveBeta(x,isBeta);
    });
  }
}

export default {
  name: "floweditor",
  data: () => ({
    inited: false,
    onChangeRecursiveCheck : false,
    renderers: [],
    code: "",
    objData: {},
    schemaFlow: {},
    uischemaFlow: {},
    destinationsField: {"type": "string","title": "Destination Reference", "oneOf":[]},
    destinationTypeById: [],
    uischemaFlowDestination: {},
    uischemaFlowOptions: {},
    uischemaFlowPipeline: {},
    activeTab: 0,
    appliedUI: "",
    appliedSchema: "",
    previousSourceType: "",
    previousDestinationType: "",
    user:{},
    ajv:{},
    destinationFormsKey: 0,
    previusTab: 0,
    currentDestType: ""
  }),
  beforeMount: async function () {
    this.ajv = new Ajv({
      allErrors: true,
      verbose: true,
      strict: false,
    });
    addFormats(this.ajv);
    await this.initFormSchema();
    await this.setValueToObjectData(this.value);
  },
  props: ["value", "debug"],
  components: {
    JsonForms,
  },
  methods: {
    /**
     * Initialize JsonForms schema with dynamic source, dynamic destinations and relative settings
     * @returns {Promise<void>}
     */
    initFormSchema: async function () {
      this.uischemaFlow = schemas.getSchemaUi("flow");
      let initialSchema = schemas.getSchema("flow");
      this.uischemaFlowDestination = schemas.getSchemaUi("flow-destinations");
      this.uischemaFlowOptions = schemas.getSchemaUi("flow-options");
      this.uischemaFlowPipeline = schemas.getSchemaUi("flow-pipeline");
      this.currentDestType = "";

      /**
       * Check and initialize source settings
       */
      if (this.value?.trigger?.scheduleConf != null && this.value?.trigger?.scheduleConf?.sourceType != null) {
        initialSchema.properties.trigger.properties.scheduleConf.properties.source.properties.settings = initialSchema.settingsOptions[this.value.trigger.scheduleConf.sourceType];
      }

      /**
       * Check and initialize destination and destination settings
       */
      //TODO: check for re-opening?
      await this.getDestinations();
      //initialSchema.properties.destinations.items.properties.ref = this.destinationsField;
      if (this.destinationExists(this.value))
      {
        let currentDestinationType = this.destinationTypeById[this.value.destinations[0].ref];
        initialSchema.destinationType = currentDestinationType;
        initialSchema.properties.destinations.items.properties.settingsOverride = initialSchema.destinationsSettingsOptions[currentDestinationType];
        //this.value.destinations[0].settingsOverride.destinationType = currentDestinationType;
        this.currentDestType = currentDestinationType;
      }
      this.schemaFlow = initialSchema;
    },
    /**
     * Set Object Value to JsonForms Schema and UI
     * @param value
     * @returns {Promise<void>}
     */
    setValueToObjectData: async function (value) {
      //this.appliedSchema = JSON.stringify(this.schemaFlow, null, 2);
      //this.appliedUI = JSON.stringify(this.uischemaFlow, null, 2);
      this.objData = value;

      /**
       * Set previousSourceType
       */
      if (this.value?.trigger?.scheduleConf != null && this.value?.trigger?.scheduleConf?.sourceType != null) {
        this.previousSourceType = this.value.trigger.scheduleConf.sourceType;
      }

      /**
       * Set previousDestinationType and destination mapping fix
       */
      this.manageDestinationTypeAndDestinationFileMapping();
      this.inited = true;
    },
    manageDestinationTypeAndDestinationFileMapping: function () {
      this.currentDestType = "";
      if (this.destinationExists(this.objData))
      {
        this.previousDestinationType = this.destinationTypeById[this.objData.destinations[0].ref];
        this.updateDestinationMappingDataBeforeGenerationCode()
        this.currentDestType = this.objData.destinations[0].destinationType;
        delete this.objData.destinations[0].destinationType;
        
        //TODO: why this one ?
        // delete this.objData.destinations[0].settingsOverride.destinationType;
      }
      const codeObj = {... this.objData};
      delete codeObj.errorMessage;
      delete codeObj.isValid;
      this.code = JSON.stringify(codeObj, null, 2);
      if (this.destinationExists(this.objData))
      {
        this.objData.destinations[0].destinationType = this.previousDestinationType;
        this.updateDestinationMappingDataAfterGeneratedCode()
        this.currentDestType = this.objData.destinations[0].destinationType;
        this.deleteEncryptionModeIfLocationTypeIsNotFTPS();
      }
    },
    updateDestinationMappingDataBeforeGenerationCode: function () {
      if (this.previousDestinationType === "File" && this.destinationOverrideExists() &&
          typeof this.objData.destinations[0].settingsOverride.formatSettings.mapping === "boolean") {
        delete this.objData.destinations[0].settingsOverride.formatSettings.mapping;
      }

      if (this.previousDestinationType === "File" && this.destinationOverrideExists() &&
          typeof this.objData.destinations[0].settingsOverride.formatSettings.mapping === "string") {
        try {
          this.objData.destinations[0].settingsOverride.formatSettings.mapping = JSON.parse(this.objData.destinations[0].settingsOverride.formatSettings.mapping);
        }
        catch (e) {}
      }
    },
    deleteEncryptionModeIfLocationTypeIsNotFTPS: function () 
    {
      if (this.previousDestinationType === "File" && 
      this.objData.destinations[0].settingsOverride.locationSettings != null && 
        this.objData.destinations[0].settingsOverride.locationType !== "FTPS") 
        {
          delete this.objData.destinations[0].settingsOverride.locationSettings.encryptionMode;
        }
    },
    updateDestinationMappingDataAfterGeneratedCode: function () {
      if (this.previousDestinationType === "File" && this.destinationOverrideExists() &&
          typeof this.objData.destinations[0].settingsOverride.formatSettings.mapping === "object") {
        this.objData.destinations[0].settingsOverride.formatSettings.mapping = JSON.stringify(this.objData.destinations[0].settingsOverride.formatSettings.mapping, null, 2);
      }
    },
  	getJSONschema: async function (url, isBeta) {
	    let schema = (await this.axios(url +"?t="+ new Date().getTime()))?.data;
	    recursiveBeta(schema,isBeta);
	    return schema;
	  },
    destinationExists: function(objectToCheck) {
      return (objectToCheck.destinations != null && objectToCheck.destinations[0] != null && objectToCheck.destinations[0].ref != null);
    },
    destinationOverrideExists: function() {
      return (this.objData.destinations[0].settingsOverride != null && this.objData.destinations[0].settingsOverride.formatSettings != null);
    },
    tabChange(event) {
      let preventClick = false;

      if (event !==  4) {
        try {
          if (this.previusTab !== undefined && this.previusTab === 4) {
            this.objData = JSON.parse(this.code);
          }
          this.manageDestinationTypeAndDestinationFileMapping();
        } catch {
          preventClick = true;
        }
      }
      this.previusTab = event;

      //TODO: verificare questo
      if (preventClick) {
        this.$nextTick(() => {
          this.tab = this.currentTab;
        });
      } else {
        this.currentTab = this.tab;
      }
    },
    checkAndValidate()
    {
      const validate = this.ajv.compile(this.schemaFlow);
      let errorMessage = "";
      const valid = validate(this.objData);
      if (valid === false) {
        const errorMessages = [];
        validate.errors.forEach((error, index) => {
          if (error.keyword === "required") 
            {
              if (error.params && error.params.missingProperty) 
              { 
                errorMessages.push(`Property ${error.params.missingProperty} is required`);
              }
            } else 
            {
              if (!error.message.includes('must match "then" schema'))
              {
                errorMessages.push(`Property '${error.instancePath.slice(1)}': ${error.message}`);
              }
            }});
          if (errorMessages.length !== 0) {
            errorMessage = errorMessages.join(" | ");
          } else {
            errorMessage = "Internal Validation Error"
          }
          this.objData.errorMessage = errorMessage;
      }
      console.log("Form Valid: " + valid);
      this.objData.isValid = valid;
    },
    async onChangeSourceForm(event, errors) {
      if (!this.onChangeRecursiveCheck && this.inited) {
        this.onChangeRecursiveCheck = true;
        

        /**
         * Check and initialize Schedule and WebHook Settings
         */
        let currentObjectData = this.objData;
        if (event?.data?.trigger.isScheduleEnabled == true && event?.data?.trigger?.scheduleConf == null)  {
          event.data.trigger.scheduleConf = {};
        }
        if (event?.data?.trigger.isWebHookEnabled == true && event?.data?.trigger?.webHookConf == null)  {
          event.data.trigger.webHookConf = {};
        }
        console.log(event.data.trigger.scheduleConf);
        console.log(event?.data?.trigger.isScheduleEnabled)
        if (event?.data?.trigger.isScheduleEnabled == false && event.data.trigger.scheduleConf != null && Object.keys(event.data.trigger.scheduleConf).length === 0)  
        {
          event.data.trigger.scheduleConf = null;
        }

        if (event?.data?.trigger.isWebHookEnabled == false && event.data.trigger.webHookConf != null && Object.keys(event.data.trigger.webHookConf).length === 0 ) 
        {
          event.data.trigger.webHookConf = null;
        }

        /**
         * Clear Source settings
         */
        if (event?.data?.trigger?.scheduleConf != null && event?.data?.trigger?.scheduleConf?.sourceType != null) {
          if (event.data.trigger.scheduleConf.source == null) {
            event.data.trigger.scheduleConf.source = {};
          }
          let currentSource = event.data.trigger.scheduleConf.sourceType;
          if (this.previousSourceType !== currentSource && 
          event.data.trigger.scheduleConf != null
           && event.data.trigger.scheduleConf.source != null ) {
            event.data.trigger.scheduleConf.source.settings = {};
            this.previousSourceType = currentSource;
            //sthis.objData = event.data;b
            if (currentSource === "file") {
              event.data.trigger.scheduleConf.source.settings.formatSettings = {};
              event.data.trigger.scheduleConf.source.settings.locationSettings = {};
            }
          }
          this.schemaFlow.properties.trigger.properties.scheduleConf.properties.source.properties.settings = this.schemaFlow.settingsOptions[currentSource];
          if (currentSource === "file") {
            if (event.data.trigger.scheduleConf.source.settings.locationType !== null &&
             event.data.trigger.scheduleConf.source.settings.locationType !== "FTPS") {
              delete event.data.trigger.scheduleConf.source.settings.locationSettings.encryptionMode;
             }
          }
        }

        this.objData = event.data;
        this.manageDestinationTypeAndDestinationFileMapping();
        this.checkAndValidate();
        await this.$emit("input", this.objData);


        /**
         * Refresh Render for enable Frontend Validation
         */
        if (this.objData != currentObjectData)
        {
          this.$nextTick(() => {
            this.objData = { ...this.objData };
          });
        }

        this.onChangeRecursiveCheck = false;
      }
    },
    async onChangeDestinationForm(event) {
        if (!this.onChangeRecursiveCheck && this.inited) {
          this.onChangeRecursiveCheck = true;

          /**
           * Clear Destinations settings
           */
          if (this.destinationExists(event.data)) {
            let currentDestinationType = this.destinationTypeById[event.data.destinations[0].ref];
            if (this.previousDestinationType === undefined || this.previousDestinationType !== currentDestinationType) {
              event.data.destinations[0].settingsOverride = {};
              this.previousDestinationType = currentDestinationType;
              this.schemaFlow.destinationType = currentDestinationType;
              this.schemaFlow.properties.destinations.items.properties.settingsOverride = this.schemaFlow.destinationsSettingsOptions[currentDestinationType];
            }
          }
          this.objData = event.data;
          this.manageDestinationTypeAndDestinationFileMapping();
          this.checkAndValidate();
          await this.$emit("input", this.objData);
          this.onChangeRecursiveCheck = false;
          //const validate = this.ajv.compile(this.schemaFlow);
          //const valid = validate(this.objData);
      }
    },
    async onChangeOptionsForm(event) {
      if (!this.onChangeRecursiveCheck && this.inited) {
        this.onChangeRecursiveCheck = true;
        this.objData = event.data;
        this.manageDestinationTypeAndDestinationFileMapping();
        this.checkAndValidate();
        await this.$emit("input", this.objData);
        this.onChangeRecursiveCheck = false;
      }
    },
    async onChangePipelineForm(event) {
      if (!this.onChangeRecursiveCheck && this.inited) {
        this.onChangeRecursiveCheck = true;
        this.objData = event.data;
        this.manageDestinationTypeAndDestinationFileMapping();
        this.checkAndValidate();
        await this.$emit("input", this.objData);
        this.onChangeRecursiveCheck = false;
      }
    },
    getDestinations: async function () {
      let response = await this.apiCall(
          `/api/destinations/list/json`,
          "get",
          null,
          {}
      );
      if (response.status === 200) {
        let destinationEnumJson = response.data.payload.content.destinations;
        destinationEnumJson.forEach(dest => {
          //this.destinationsField.oneOf.push({"const": dest.Name, "title": dest.Name});
          this.destinationTypeById[dest.Name] = dest.DestinationType;
          this.destinationTypeById[dest._id] = dest.DestinationType
        });
      }
    },
    testConnection: async function(ctx) 
    {
      let response = await this.apiCall(`/api/editableflows/test-connection`, "POST",this.objData, {context : ctx});
      if (response.status == 200) {
        this.showSuccess(response.data.payload.content.message);
      }
    },
    validateForm() {
      this.destinationFormsKey += 1;
      //const validate = this.ajv.compile(this.objData);
      //const valid = validate(this.formData);
      //this.validationErrors = valid ? [] : validate.errors;
    },
    getFieldTextToJson: function (fieldText) {
      try {
        return JSON.parse(fieldText);
      } catch {
        return fieldText;
      }
    }
  },
  created: async function () {
    //TODO: mettere renders su posto unico (globale per ogni pagina)
    const vrenders = vuetifyRenderers;

    vrenders.push({
      tester: function (schema, schemaPath) {
        try {
          let item = getElementByScope(schemaPath, schema.scope);
          if (item.options.elementLabelProp === "platform") {
            return 10;
          }
        } catch {}
        return -1;
      },
      renderer: PlatformRender,
    });

    vrenders.push({
      tester: function (schema, schemaPath) {
        try {
          let item = getElementByScope(schemaPath, schema.scope);
          if (item.options.elementLabelProp === "map") {
            return 10;
          }
        } catch {}
        return -1;
      },
      renderer: MapRender,
    });

    vrenders.push({
      tester: function (schema, schemaPath) {
        try {
          let item = getElementByScope(schemaPath, schema.scope);
          if (item.options.elementLabelProp === "monaco") {
            return 10;
          }
        } catch {}
        return -1;
      },
      renderer: MonacoRender,
    });

    vrenders.push({
      tester: function (schema, schemaPath) {
        if(schema.scope?.includes("action"))
        { 
            let item = getElementByScope(schemaPath, schema.scope);
           // console.log("ACTION",schema.scope, schema,schemaPath.options, item,schemaPath);
        }
        try {
          let item = getElementByScope(schemaPath, schema.scope);
          if (item?.options?.elementLabelProp == "apiselect") {
            //console.log("got it");
            return 10;
          }
        } catch {}
        return -1;
      },
      renderer: ApiSelectRender,
    });

    this.renderers = vrenders;

    //console.log(this.objData);
    //this.$options.components.JsonForms.renderers.push(...vuetifyRenderers);
  },
  computed:{
    hasError: function(){
      return this.$refs.formdbase?.jsonforms?.core?.errors?.length>0 &&
      this.$refs.formdest?.jsonforms?.core?.errors?.length>0 &&
      this.$refs.formopt?.jsonforms?.core?.errors?.length>0 &&
      this.$refs.formpipeline?.jsonforms?.core?.errors?.length>0;
    }
  },
  watch: {
    value: async function (newValue, oldValue) {
      if (!this.onChangeRecursiveCheck)
      {
        if (oldValue.id !== newValue.id || oldValue.destinationType !== newValue.destinationType)
        {
          await this.initFormSchema();
        }
        this.inited = false;
        await this.setValueToObjectData(this.value);
        this.destinationFormsKey += 1;
      }
    }
  },
};
</script>
