import axios from "axios";

let schemas = ["message", "user", "flow", "flow-destinations", "flow-options", "flow-pipeline", "platform"];

class SchemasService {
  constructor(schemas, axios) {
    this.schemas = schemas;
    this.schemasData = {};
    this.axios = axios;
  }

  async load() {
    // let sss= await this.axios('https://httpbin.org/delay/10');
    this.schemasData["ui"] = {};
    this.schemasData["schema"] = {};
    for (const element of this.schemas) {
      let uiResp = await this.axios(
        `/assets/settings/schemas/ui${element}.json`
      );
      this.schemasData["ui"][element] = uiResp.data;
      let schemaResp = await this.axios(
        `/assets/settings/schemas/${element}.json`
      );
      this.schemasData["schema"][element] = schemaResp.data;
    }
  }

  getSchemaUi(name) {
    return this.schemasData["ui"][name];
  }

  getSchema(name) {
    return this.schemasData["schema"][name];
  }
}

const instance = new SchemasService(schemas, axios);
instance.load();
console.log("SCHEMAS", instance.schemasData);
export default instance;
