<template>
  <v-app class="ips-box">
    <v-app-bar app color="blue-gray">
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title>{{ title }}</v-toolbar-title>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" left temporary fixed app>
      <v-list>
        <v-list-item v-if="user">
          <v-list-item-avatar>
            <img :src="user.picture" />
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="text-h6">
              {{ user.nickname }}
            </v-list-item-title>
            <v-list-item-subtitle>
              {{ user.email }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-list-item v-if="user">
          <v-list-item-content>
            <v-select
              :items="user.tenants"
              v-model="currentTenant"
              item-value="name"
              @change="tenantChange"
              :disabled="user.tenants && user.tenants.length < 2"
            >
              <template slot="selection" slot-scope="data">
                {{ data.item.name }}
              </template>
              <template slot="item" slot-scope="data">
                {{ data.item.name }}
              </template>
            </v-select>
          </v-list-item-content>
        </v-list-item>

        <v-list-item-group color="primary">
          <v-list-item
            v-for="(item, i) in menuFiltered"
            :key="item.route"
            @click="navigate(item)"
          >
            <v-list-item-icon >
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ item.text }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <router-view name="left"></router-view>

      <v-footer padless absolute top="-1px">
        <v-col class="text-center" cols="12">
          <a @click.prevent="logout" href="#">Logout</a>
        </v-col>
        <v-col
          class="text-center caption"
          style="max-height: 21px; margin: 0px; padding: 0px; background: #eee"
          cols="12"
        >
          {{ version }}
        </v-col>
      </v-footer>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-snackbar
      v-model="snackbar.open"
      :timeout="snackbar.timeout"
      :color="snackbar.color"
      multi-line
      class="snackbar-content"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn rounded outlined depressed color="white" text v-bind="attrs" @click="snackbar.open = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay :opacity="1" :value="loading">
      <v-progress-circular indeterminate size="64">
        Loading...
      </v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import userService from "./services/user";

export default {
  name: "App",
  inject: ["schemaService"],
  data: () => ({
    loading: true,
    version: "unassigned",
    snackbar: {
      color: "primary",
      open: false,
      text: "My timeout is set to 2000.",
      timeout: 2000,
      color: "blue-grey",
    },
    user: {},
    title: "",
    connection: "",
    drawer: false,
    currentModule: {},
    modules: [],
    currentTenant: "",
    items: [
      {text: "Dashboard", icon: "mdi-text-search", route: "dashboard", isBeta: false},
      {text: "Destinations", icon: "mdi-adjust", route: "destinations", isBeta: false},
      {text: "Flows", icon: "mdi-transit-connection-variant", route: "flows", isBeta: false},
      {text: "Queue", icon: "mdi-tray-full", route: "queue", isBeta: false},
      //{ text: "Logs", icon: "mdi-text-search", route: "logs" , isBeta:false},
      {text: "Settings", icon: "mdi-cog-outline", route: "settings"},
      {text: "Actions", icon: "mdi-application-cog-outline", route: "actions", isBeta: true},
      {text: "Platforms", icon: "mdi-layers-outline", route: "platforms", isBeta: false},
      {text: "User", icon: "mdi-account", route: "user", isBeta: false},
    ],
  }),
  beforeMount: async function () {},
  mounted: async function () {
    this.title =
      this.$router.currentRoute.params.title ??
      this.$router.currentRoute.meta.title ??
      this.currentModule.label;

    await this.schemaService.load();
    this.loading = false;
    this.modules = this.$router.options.routes.filter((x) => x.menu);
    let index = Math.max(
      this.modules.findIndex((x) => x.name == this.$route.name, this),
      0
    );
    this.currentModule = this.modules[index];
    if (!this.currentModule.closable) {
      this.drawer = !this.currentModule.closable;
    }

    this.version =
      process.env.VUE_APP_VERSION + "  " + process.env.VUE_APP_COMMITMESSAGE; //'v' +( process.env.VERCEL_GIT_COMMIT_REF ?? '??') +'.'+  (process.env.VERCEL_GIT_COMMIT_MESSAGE ?? '??');

    this.listen("app:loading", this, function (sender, data) {
      console.log("app:loading", data);
      sender.loading = data;
    });

    this.updateUser();
    this.listen("user:loggedin", this, function (sender, data) {
      console.log("user:loggedin", data);
      sender.updateUser();
      this.send("app:loading", false);
    });
    this.listen("snackbar:push", this, function (sender, data) {
      sender.snackbar.text = data.message;

      sender.snackbar.color = "primary";
      if (data.type == "success") {
        sender.snackbar.color = "success";
      } else if (data.type == "error") {
        sender.snackbar.color = "red accent-2";
      } else if (data.type == "saving") {
        sender.snackbar.color = "primary";
      } else if (data.type == "warning") {
        sender.snackbar.color = "orange";
      }
      sender.snackbar.timeout = data.timeout ?? 2000;
      sender.snackbar.open = true;
      console.log(data);
    });
  },
  computed:{
    menuFiltered:  function(){
      return this.items.filter(x=>x.isBeta==false || this.user?.hasBetaFeatures);
    }
  },
  watch: {
    $route(to, from) {
      //  console.log("route changed", to);
      this.title =
        this.$router.currentRoute.params.title ??
        this.$router.currentRoute.meta.title ??
        this.currentModule.label;
    },
  },
  components: {},
  methods: {
    updateUser: async function () {
      this.drawer = false;
      this.user = userService.user;
      if (this.user != null) {
        if (this.user.tenants.length == 0) {
          console.log("no tenant founds");
          return;
        }
        this.currentTenant = (
          this.user.tenants.find(
            (element) => element.name == this.user.tenant,
            this
          ) ?? this.user.tenants[0]
        ).name;
      }
    },
    tenantChange: async function (item) {
      var oldUser = userService.user;
      console.log(`changed.... ${oldUser.tenant} => ${item}`);
      //console.log(this.currentTenant, item);
      userService.setTenant(this.currentTenant);
      this.$router.push({ name: "dashboard" }).catch(() => {});
      this.$router.go();
    },
    navigate: async function (item) {
      console.log("moving to", item);
      this.$router.push({ name: item.route }).catch(() => {});
    },
    logout: async function (item) {
      this.drawer = false;
      userService.remove();
      this.$router.push({ name: "login" }).catch(() => {});
    },
  },
};
</script>

<style lang="scss">
  @import './public/assets/styles/main.scss';

  .snackbar-content {
  max-width: 100%;
  white-space: pre-wrap; 
  word-wrap: break-word; 
  overflow-wrap: break-word;
  word-break: break-word;
}
</style>
