<script>

import PlatformEditor from "@/components/shared/PlatformEditor.vue";
import AbstractPlatform from "@/components/platforms/abstractPlatform.vue";
import PlatformService from "@/services/platformService";

export default {
  name: "PlatformGlobal",
  components: {PlatformEditor},
  extends: AbstractPlatform,
  beforeMount: async function ()  {
    await this.searchPlatform();
    this.tags = this.getTags();
    this.groups = this.getGroups();
  },
  methods: {
    searchPlatform: async function () {
      this.loading = true;
      let platforms = await this.platformService.getPlatformsGlobal();

      this.platforms = platforms;
      this.platformsFiltered = platforms;
      this.loading = false;
    },
    insert: async function (data) {
      await this.baseInsert(data, true);
    },
    update: async function (data) {
      await this.baseUpdate(data, true);
    },
    deleteItem: async function () {
      await this.baseDelete(true);
    },
  },
};
</script>