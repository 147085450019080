<template>
  <v-container>
    <v-row >
      <v-col class="d-flex justify-end">
        <v-menu bottom right style="z-index: 100">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              elevation="24"
              style="z-index: 100"
              v-bind="attrs"
              v-on="on"
              fab
              large
              fixed
              top
              right
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="newItem">
              <v-list-item-icon>
                <v-icon>mdi-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>New Item</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item @click="triggerFileInput">
              <v-list-item-icon>
                <v-icon>mdi-upload</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>Import</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>
        <input type="file" accept=".json" ref="fileInput" @change="handleFileSelect" style="display: none;" />
      </v-col>
      <v-col cols="12">
        <v-dialog class="ips-dialog" v-model="deleting" width="400">
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              Confirm before delete
            </v-card-title>
            <v-card-text> Delete {{ this.editingItem.name }}? </v-card-text>
            <v-divider></v-divider>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="deleting = false">
                Cancel
              </v-btn>
              <v-btn color="error" @click="deleteItem()">
                <v-icon>mdi-trash-can</v-icon>Delete
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog class="ips-dialog" v-model="dialog" hide-overlay persistent>
          <v-card min-height="100vh">
            <v-toolbar dark color="grey darken-4">
              <v-btn icon dark @click="dialog = false">
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>{{ editingTitle }}</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn dark text @click="save">Save</v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-container>
              <flow-editor v-model="editingItem" :value="editingItem" />
            </v-container>
          </v-card>
        </v-dialog>

        <v-list-item v-for="(item, i) in flows" :key="item.id">
          <v-card width="100%" class="mb-8">
            <v-row>
              <v-col cols="12">

                <v-list-item three-line>
                  <v-list-item-content>
                    <v-list-item-title class="text-h5 mb-2 mt-2">
                      <a @click="view(item)" class="text-h5 py-2 ips-link" style="color: black" plain>
                        {{ item.name }}
                      </a>
                      <v-chip
                          class="ml-1 text-capitalize text-caption"
                          small
                          :color="item.environment === 'prod' ? 'green' : (item.environment === 'test' ? 'yellow' : 'grey')">
                        {{item.environment}}
                      </v-chip>
                    </v-list-item-title>
                    <v-list-item-subtitle class="mb-5">
                      <v-row>
                        <v-col cols="12">
                          <v-chip
                              class="mr-2"
                              v-if="item.trigger.isScheduleEnabled && item.trigger.scheduleConf"
                              label>
                            {{ item.trigger.scheduleConf.sourceType }}
                          </v-chip>
                          <v-chip
                              class="mr-2"
                              color="blue-grey lighten-4"
                              v-if="item.trigger.isWebHookEnabled"
                              label>
                            Webhook
                          </v-chip>
                          <v-icon> mdi-play</v-icon>
                          <v-chip
                              class="ml-2"
                              v-for="dest in item.destinations"
                              :key="dest.id"
                              color="blue-grey lighten-5"
                              label>
                            {{    dest.destinationReferenced
                              ? dest.destinationReferenced.destinationType
                              : "NOT FOUND"
                            }}
                          </v-chip>
                        </v-col>
                      </v-row>
                      <v-row class="mt-1">
                        <v-col cols="3"
                               v-if="
                        item.trigger.isScheduleEnabled &&
                        item.trigger.scheduleConf">
                          <v-text-field label="CRON" prepend-icon="mdi-timer-check-outline" readonly
                                        hide-details="auto"
                                        class="text-h6 font-weight-regular"
                                        v-if="
                          item.trigger.isScheduleEnabled &&
                          item.trigger.scheduleConf"
                                        :value="item.trigger.scheduleConf.cronExpr">
                          </v-text-field>
                        </v-col>
                        <v-col
                            cols="9"
                            class="d-flex align-center"
                            v-if="
                        item.trigger.isWebHookEnabled &&
                        item.trigger.webHookConf
                      "
                        >
                          <v-btn @click="copyToClipBoard(item)"  
                              color="blue-grey lighten-5"
                              depressed>
                            {{
                              basePath.replace(/\/+$/, "") +
                              "/api/wh/" +
                              item.tenantId +
                              "/" +
                              item.trigger.webHookConf.path
                             
                            }}<v-icon class="ml-2">mdi-content-copy</v-icon>
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>
              </v-col>
            </v-row>

            <v-card-actions class="py-2 ips-card-actions">
              <v-menu bottom right>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn depressed rounded icon color="blue-grey darken-1" v-bind="attrs" v-on="on" left>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item @click="startDelete(item)">
                    <v-list-item-title>
                      <v-icon>mdi-trash-can</v-icon> Delete</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="reload(item)">
                    <v-list-item-title>
                      <v-icon>mdi-reload</v-icon> Reload</v-list-item-title
                    >
                  </v-list-item>
                  <v-list-item @click="exportFlow(item)">
                    <v-list-item-title>
                      <v-icon>mdi-file-export</v-icon>
                      Export
                      </v-list-item-title>
                  </v-list-item>
                  <v-list-item @click="exportFlowWithDestination(item)">
                    <v-list-item-title>
                      <v-icon>mdi-file-export</v-icon>
                      Export With Destinations
                      </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

              <v-btn depressed rounded color="blue-grey lighten-5" @click="edit(item)">Edit</v-btn>
              <v-btn
                depressed
                rounded
                color="primary"
                class="px-6"
                @click="run(item)"
                v-if="item.trigger.isScheduleEnabled"
              >
                Run now <v-icon> mdi-play</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-list-item>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import userService from "../../services/user";
import FlowEditor from "../shared/FlowEditor.vue";
import BarChart from "../charts/BarChart.vue";

let flowConfig = {};
export default {
  name: "flows",
  components: {BarChart, FlowEditor},
  beforeMount: async function () {
    this.queryAllFlows();
  },
  computed: {
    basePath: function () {
      return this.getWebhookUrl();
    },
    isSaveDisabled: function() {
      return !this.editingItem.isValid;
    }
  },
  methods: {
    uploadFlow: async function () {
    if (this.jsonFile) {
    const formData = new FormData();
    formData.append('file', this.jsonFile);
    var response = await this.apiCallForUpload('/api/editableflows/upload',formData,{},{})}
    if (response.status === 200) {
      let replacement = response.data.payload.content;
      this.flows.push(replacement);
      this.editingItem.id = replacement.id;
      this.showSuccess("flow successfully created");
      await this.reload(this.editingItem);
      window.location.reload();
    }
    },
    copyToClipBoard: async function (item) {
      let textToCopy = this.getFlowWebHookUrl(item.tenantId, item.trigger.webHookConf.path);
      navigator.clipboard.writeText(textToCopy);
    },
    exportFlow: async function (data) {
      this.editingItem = data;
      console.log(this.editingItem);
      return await this.apiCallForDownload(data.name,`/api/editableflows/${this.editingItem.id}/download`,"GET",{},{});
    },
    exportFlowWithDestination: async function (data) {
      this.editingItem = data;
      await this.apiCallForDownload(data.name,`/api/editableflows/${this.editingItem.id}/download`,"GET",{},{});
      for (const d of this.editingItem.destinations) {
        const name = d.destinationReferenced.name;
        await this.apiCallForDownload(name, `/api/destinations/${d.destinationReferenced.id}/download`, "GET", {}, {});
      }
    },
    run: async function (data) {
      //let tenantSsiId = localStorage.getItem("userTenant");
      let user = userService.user;
      let tenantSsiId = user.tenant;
      this.showSaving(
        "Starting job... the operation is syncrounous so it can take a little"
      );
      let response = await this.schedulerCall(
        `/api/jobs/${tenantSsiId}/${data.id}`,
        "POST",
        {},
        {}
      );
      if (response.status == 200 || response.status == 202) {
        this.showSuccess("Job executed");
      }
    },
    //TODO: Repeated function here and in flowView. Create a service for this
    save: async function (data) {
      let responseStatus = 0;
      this.showSaving("Flow is saving...");
      console.log(this.editingItem)
      if (this.editingItem.isValid === false) {
        this.showError(this.editingItem.errorMessage)
      } else 
      {
        if (this.editingItem.destinations[0].settingsOverride != null && this.editingItem.destinations[0].settingsOverride.formatSettings != null &&
            this.editingItem.destinations[0].settingsOverride.formatSettings.mapping != null &&
            typeof this.editingItem.destinations[0].settingsOverride.formatSettings.mapping === "string") {
          try {
            this.editingItem.destinations[0].settingsOverride.formatSettings.mapping = JSON.parse(this.editingItem.destinations[0].settingsOverride.formatSettings.mapping);
          }
          catch (e) {}
        }
        delete this.editingItem.isValid;
        delete this.editingItem.errorMessage;
      if (
        this.editingItem == null ||
        this.editingItem.id == undefined ||
        this.editingItem.id == ""
      ) {
        responseStatus = await this.insert(this.editingItem);
      } else {
        responseStatus = await this.update(this.editingItem);
      }
      if (responseStatus == 200) {
        await this.reload(this.editingItem);
        window.location.reload();
      }
      }
    },
    reload: async function (data) {
      //await this.reloadEgress(data);
      await this.reloadScheduled(data);
    },
    reloadScheduled: async function (data) {
      this.showSaving("Reloading Scheduler");
      let response = await this.schedulerCall(
        `/api/jobs/reload/${data.id}`,
        "POST",
        {},
        {}
      );
      this.showSuccess("Scheduler reloaded");
      this.dialog = false;
    },    
    handleFileSelect : async function(event) {
      if (event.target.files.length > 0) {
        this.jsonFile = event.target.files[0];
        await this.uploadFlow();
        this.$refs.fileInput.value = null;
      }
    },
    triggerFileInput : function() {
      this.$refs.fileInput.click();
    },
    insert: async function (data) {
      let response = await this.apiCall(`/api/editableflows`, "POST", data, {});
      if (response.status == 200) {
        let replacement = response.data.payload.content;
        this.flows.push(replacement);
        this.editingItem.id = replacement.id;
        this.showSuccess(`Flow added with id ${replacement.id}`);
      }
      return response.status;
    },
    update: async function (data) {
      this.showSaving("Flow is saving...");
      let response = await this.apiCall(
        `/api/editableflows/${this.editingItem.id}`,
        "PUT",
        data,
        {}
      );
      if (response.status == 200) {
        let replacement = response.data.payload.content;
        let idx = this.flows.findIndex(
          (x) => x.id == this.editingItem.id,
          this
        );
        console.log(idx);
        this.flows.splice(idx, 1, replacement);
        this.showSuccess("Flow updated");
      }
      return response.status;
    },
    deleteItem: async function () {
      this.showSaving("Deleting Item " + this.editingItem.name);
      let response = await this.apiCall(
        `/api/editableflows/${this.editingItem.id}`,
        "DELETE",
        {},
        {}
      );
      if (response.status == 200) {
        this.showSuccess("Item deleted");
        let idx = this.flows.findIndex(
          (x) => x.id == this.editingItem.id,
          this
        );
        this.flows.splice(idx, 1);
      } else {
        this.showError(response.data.metadata.uiMessages.errors[0].uiMessages);
      }
      this.deleting = false;
    },
    startDelete: async function (data) {
      this.editingItem = data;
      this.deleting = true;
    },
    edit: async function (data) {
      if (data.destinations && data.destinations.length > 0) {
        data.destinations.forEach((dest) => {
          delete dest.destinationReferenced;
        });
      }

      this.code = JSON.stringify(data, null, 2);
      this.dialog = true;
      this.editingTitle = "Editing " + data.name;
      this.editingItem = data;
    },
    newItem: async function (data) {
      this.user = userService.user;
      let newJson = {
        tenantId: this.user.tenant,
        name: undefined,
        trigger: {
          isWebHookEnabled: false,
          isScheduleEnabled: false,
          webHookConf: null,
          scheduleConf: null,
        },
        destinations: [],
        options: {
          buffer: {
            maxItems: 100,
            debounceMillis: 1000,
          },
          retry: {
            maxAttempts: 5,
            strategy: "constantBackoff",
            millis: 5000
          }
        }
      };
      this.code = JSON.stringify(newJson, null, 2);
      this.dialog = true;
      this.editingTitle = "New flow";
      this.editingItem = newJson;
    },
    view: async function (item) {
      this.$router
        .push({ name: "flows.view", params: { id: item.id } })
        .catch(() => {});
    },
    queryAllFlows:async function (item) {
      let response = await this.apiCall(
          "/api/editableflows/list",
          "POST",
          {
            "orderBy": [
              {
                "name": "name",
                "direction": "asc"
              }]
          },
          {}
      );
      if (response.status === 200) {
        this.flows = response.data.payload.content;
      }
    }
  },
  data: () => ({
    flows: [],
    dialog: false,
    code: "",
    editingItem: {},
    editingTitle: "",
    deleting: false,
    config: flowConfig,
    user: {},
    isEditDialogOpen: false,
    jsonFile: null
  }),
};
</script>
