<template>
  <v-container fluid>
    <v-row>
    <v-card class="mt-2">
          <v-tabs fixed-tabs class="py-2" v-model="tab" tabindex="0" center-active @change="setQueryParamFilter">
            <v-tabs-slider color="primary"></v-tabs-slider>
            <v-tab key="completed">Completed Messagges</v-tab>
            <v-tab key="queue">Queue Messagges</v-tab> 
          </v-tabs>
          <v-tabs-items class="vw-100" v-model="tab">
            <v-tab-item key="completed" :eager="true">
              <queue ref="completedRef" :inProgressQueue=false></queue>
            </v-tab-item>
            <v-tab-item key="queue" :eager="true">
              <queue ref="queueRef" :inProgressQueue=true></queue>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
    </v-row>
    </v-container>
</template>

<script>

import Queue from "./queue.vue";
export default {
  name: "QueueView",
  components: {
    queue: Queue,
  },
  data: () => ({
    tab : null
  }),
  mounted : function () {
    const queryFilter = this.$route.query.filter;
    if(queryFilter) {
      const filtersJsonString = atob(this.$route.query.filter);
      const filtersDecoded = JSON.parse(filtersJsonString);
      this.tab = filtersDecoded["queueType"] == "tenant" ? 0 : 1;
    }
  }
}

</script>