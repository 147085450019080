<template>
  <v-container>
  <v-tabs>
    <v-tab :key="0">Platform</v-tab>
    <v-tab :key="1">Raw data</v-tab>
    <v-tab-item :key="0">
      <json-forms :data="objData" :schema="schema" :uischema="uischema"
                  @change="onChangeSourceForm"  :renderers="renderers" ref="formdbase"
                  :validationMode="'ValidateAndShow'" :ajv="ajv"
      />
    </v-tab-item>
    <v-tab-item :key="1">
      <vue-monaco-editor v-model="code" height="600px" language="json" loading="false" />
    </v-tab-item>
  </v-tabs>
</v-container>
</template>


<script>

import { JsonForms } from "@jsonforms/vue2";
import Vuetify from "vuetify";
import Vue from "vue";
import schemas from "../../services/schemas";
import Ajv from 'ajv';
import addFormats from 'ajv-formats';
import {vuetifyRenderers} from "@jsonforms/vue2-vuetify";
import {getElementByScope} from "@/services/uiFormUtils";
import MonacoRender from "@/components/shared/renderers/MonacoRender.vue";
import ArrayRender from "@/components/shared/renderers/ArrayRender.vue";
Vue.use(Vuetify);

export default {
  name: "platform-editor",
  data: () => ({
    inited: false,
    onChangeRecursiveCheck : false,
    renderers: [],
    code: "",
    objData: {},
    schema: {},
    uischema: {},
    user:{},
    ajv:{},
    currentDestType: ""
  }),
  beforeMount: async function () {
    this.ajv = new Ajv({
      allErrors: true,
      verbose: true,
      strict: false,
    });
    addFormats(this.ajv);
    await this.initFormSchema();
    await this.setValueToObjectData(this.value);
  },
  props: ["value", "debug"],
  components: {
    JsonForms,
  },
  methods: {
    initFormSchema: async function () {
      this.uischema = schemas.getSchemaUi("platform");
      this.schema = schemas.getSchema("platform");
    },
    setValueToObjectData: async function (value) {
      this.objData = value;
      this.inited = true;
    },
    async onChangeSourceForm(event, errors) {
      if (!this.onChangeRecursiveCheck && this.inited) {
        this.onChangeRecursiveCheck = true;
        this.objData = event.data;
        let currentObjectData = event.data;
        await this.$emit("input", this.objData);

        const codeObj = {... this.objData};
        delete codeObj.errorMessage;
        delete codeObj.isValid;
        this.code = JSON.stringify(codeObj, null, 2);

        if (this.objData !== currentObjectData)
        {
          this.$nextTick(() => {
            this.objData = { ...this.objData };
          });
        }

        this.onChangeRecursiveCheck = false;
      }
    },
    },
  created: async function () {
    this.renderers = vuetifyRenderers;

    this.renderers.push({
      tester: function (schema, schemaPath) {
        try {
          let item = getElementByScope(schemaPath, schema.scope);
          if (item.options.elementLabelProp === "map") {
            return 10;
          }
        } catch {}
        return -1;
      },
      renderer: ArrayRender,
    });

    this.renderers.push({
      tester: function (schema, schemaPath) {
        try {
          let item = getElementByScope(schemaPath, schema.scope);
          if (item.options.elementLabelProp === "monaco") {
            return 10;
          }
        } catch {}
        return -1;
      },
      renderer: MonacoRender,
    });
  },
  computed:{},
  watch: {
    value: async function (newValue, oldValue) {
      if (!this.onChangeRecursiveCheck)
      {
        if (oldValue.id !== newValue.id || oldValue.destinationType !== newValue.destinationType)
        {
          await this.initFormSchema();
        }
        this.inited = false;
        await this.setValueToObjectData(this.value);
      }
    }
  },
};
</script>
